import { DeclarationPage } from '@declaration/pages';
import { DeclarationSection } from '@declaration/sections';
import { Question } from '@declaration/questions';
import { Step } from '@shared/claim/steps';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { InsurerName } from '@declaration/types/insurer';
function isInsurerPageConfiguration(configuration) {
    return (typeof configuration !== 'string' &&
        Object.keys(configuration).some((v) => Object.values(InsurerName).includes(v)));
}
function isTrigramPageConfiguration(configuration) {
    return (typeof configuration !== 'string' &&
        Object.keys(configuration).some((v) => Object.values(ClaimTypeTrigram).includes(v)));
}
function isInsurerSectionConfiguration(configuration) {
    return (typeof configuration !== 'string' &&
        Object.keys(configuration).some((v) => Object.values(InsurerName).includes(v)));
}
function isTrigramSectionConfiguration(configuration) {
    return (typeof configuration !== 'string' &&
        Object.keys(configuration).some((v) => Object.values(ClaimTypeTrigram).includes(v)));
}
export function pageFromStepConfiguration(configuration, typeTrigram, insurer) {
    var _a, _b, _c;
    if (configuration.page === DeclarationPage.Questions)
        return configuration.page;
    const pageConfiguration = configuration.page;
    if (isInsurerPageConfiguration(pageConfiguration)) {
        const insurerConfiguration = insurer
            ? (_a = pageConfiguration[insurer]) !== null && _a !== void 0 ? _a : pageConfiguration.default
            : pageConfiguration.default;
        if (isTrigramPageConfiguration(insurerConfiguration)) {
            const trigramConfiguration = (_b = insurerConfiguration[typeTrigram]) !== null && _b !== void 0 ? _b : insurerConfiguration.default;
            return trigramConfiguration;
        }
        else
            return insurerConfiguration;
    }
    if (isTrigramPageConfiguration(pageConfiguration)) {
        const trigramConfiguration = (_c = pageConfiguration[typeTrigram]) !== null && _c !== void 0 ? _c : pageConfiguration.default;
        return trigramConfiguration;
    }
    return pageConfiguration;
}
export function sectionFromStepConfiguration(configuration, typeTrigram, insurer) {
    var _a, _b, _c;
    if (configuration.page !== DeclarationPage.Questions) {
        return null;
    }
    const sectionConfiguration = configuration.section;
    if (!sectionConfiguration)
        return null;
    if (isInsurerSectionConfiguration(sectionConfiguration)) {
        const insurerConfiguration = insurer
            ? (_a = sectionConfiguration[insurer]) !== null && _a !== void 0 ? _a : sectionConfiguration.default
            : sectionConfiguration.default;
        if (isTrigramSectionConfiguration(insurerConfiguration)) {
            const trigramConfiguration = (_b = insurerConfiguration[typeTrigram]) !== null && _b !== void 0 ? _b : insurerConfiguration.default;
            return trigramConfiguration;
        }
        else
            return insurerConfiguration;
    }
    if (isTrigramSectionConfiguration(sectionConfiguration)) {
        const trigramConfiguration = (_c = sectionConfiguration[typeTrigram]) !== null && _c !== void 0 ? _c : sectionConfiguration.default;
        return trigramConfiguration;
    }
    return sectionConfiguration;
}
export const StepConfigurations = {
    [Step.Index]: { page: DeclarationPage.Index },
    [Step.Unavailable]: {
        page: DeclarationPage.Questions,
        question: null,
        section: null,
    },
    [Step.ContractChoice]: { page: DeclarationPage.Contracts },
    [Step.ContractInformation]: { page: DeclarationPage.ContractInformation },
    [Step.ContractModification]: { page: DeclarationPage.ContractModification },
    [Step.ClaimType]: { page: DeclarationPage.ClaimType },
    [Step.ClaimDate]: {
        page: DeclarationPage.Questions,
        question: Question.ClaimDateQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.DeclarationDeadlineOver]: { page: DeclarationPage.DeclarationDeadlineOver },
    [Step.NoFollowUp]: {
        page: DeclarationPage.NoFollowUpIndex,
    },
    [Step.NoAdministrativeFollowup]: {
        page: DeclarationPage.NoAdministrativeFollowupIndex,
    },
    [Step.NoDamage]: {
        page: DeclarationPage.NoDamage,
    },
    [Step.RobberyPNOExit]: {
        page: DeclarationPage.RobberyPNOExit,
    },
    [Step.YourDamages]: {
        page: DeclarationPage.OnlyYourDamages,
    },
    [Step.TenantInsurer]: {
        page: DeclarationPage.TenantInsurer,
    },
    [Step.OwnerInsurer]: {
        page: DeclarationPage.OwnerInsurer,
    },
    [Step.ValuationOwnerInsurer]: {
        page: DeclarationPage.ValuationOwnerInsurer,
    },
    [Step.RoomDamagesOwnerInsurer]: {
        page: DeclarationPage.RoomDamagesOwnerInsurer,
    },
    [Step.ClaimCompleted]: {
        page: DeclarationPage.ClaimCompleted,
    },
    [Step.ClaimCompletedArtisan]: {
        page: DeclarationPage.ClaimCompletedArtisan,
    },
    [Step.NoSuitableExpertiseSchedule]: {
        page: DeclarationPage.NoSuitableExpertiseSchedule,
    },
    [Step.TransferToPae]: {
        page: DeclarationPage.TransferToPae,
    },
    [Step.DeclarationDocumentPending]: { page: DeclarationPage.DeclarationDocumentPending },
    [Step.PremiseFurnished]: {
        page: DeclarationPage.Questions,
        question: Question.IsPremiseFurnishedQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.PremiseVacant]: {
        page: DeclarationPage.Questions,
        question: Question.IsPremiseVacantQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.LeaveBeforeSinister]: {
        page: DeclarationPage.Questions,
        question: Question.IsLeaveBeforeSinisterQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.LeakageCauseLocation]: {
        page: DeclarationPage.Questions,
        question: Question.LeakageCauseLocationQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.LeakageCauseLocationHouse]: {
        page: DeclarationPage.Questions,
        question: Question.LeakageCauseLocationHouseQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.WaterLeakageCauseFixed]: {
        page: DeclarationPage.Questions,
        question: Question.WaterLeakageCauseFixedQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.WaterLeakageCauseLocalized]: {
        page: DeclarationPage.Questions,
        question: Question.WaterLeakageCauseLocalizedQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.HasDamages]: {
        page: DeclarationPage.Questions,
        question: Question.HasDamagesQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.WaterLeakageCauseLocalizedPlumberIntervened]: {
        page: DeclarationPage.Questions,
        question: Question.WaterLeakageCauseLocalizedPlumberIntervenedQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.WaterLeakageCauseLocalizedPlumberIntervenedInvoice]: {
        page: DeclarationPage.Questions,
        question: Question.WaterLeakageCauseLocalizedPlumberIntervenedInvoiceQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.TenantNotCovered]: { page: DeclarationPage.TenantNotCovered },
    [Step.IsRehousingNeeded]: {
        page: DeclarationPage.Questions,
        question: Question.IsRehousingNeededQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.NeedHelpToFindLeakage]: {
        page: DeclarationPage.Questions,
        question: Question.NeedHelpToFindLeakageQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.BeCalledQualificationQuestion]: {
        page: DeclarationPage.Questions,
        question: Question.BeCalledQualificationQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.BeCalledConfirmation]: { page: DeclarationPage.CallbackConfirmation },
    [Step.BeCalledConfirmationTNG]: { page: DeclarationPage.CallbackConfirmationTNG },
    [Step.NeedRehousing]: { page: DeclarationPage.NeedRehousing },
    [Step.ShouldRepairLeakage]: { page: DeclarationPage.ShouldRepairLeakage },
    [Step.ShouldLocalizeLeakage]: { page: DeclarationPage.ShouldLocalizeLeakage },
    [Step.WaterLeakageCauseAdvisorContact]: { page: DeclarationPage.WaterLeakageCauseAdvisorContact },
    [Step.Smuggling]: { page: DeclarationPage.Smuggling },
    [Step.WaterLeakageCauseNotLocalized]: { page: DeclarationPage.WaterLeakageCauseNotLocalized },
    [Step.ShouldContactOwnerLeakage]: { page: DeclarationPage.ShouldContactOwnerLeakage },
    [Step.NumberOfRoomDamages]: {
        page: DeclarationPage.Questions,
        question: Question.NumberOfRoomDamagesQuestion,
        section: {
            default: DeclarationSection.QualificationSection,
            [ClaimTypeTrigram.StormSnowHail]: DeclarationSection.InternalDamagesSection,
            [ClaimTypeTrigram.Fire]: DeclarationSection.InternalDamagesSection,
        },
    },
    [Step.NumberOfPersonalPropertyDamages]: {
        page: DeclarationPage.Questions,
        question: Question.NumberOfPersonalPropertyDamagesQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.HasPersonalPropertyDamages]: {
        page: DeclarationPage.Questions,
        question: Question.HasPersonalPropertyDamagesQuestion,
        section: {
            default: DeclarationSection.QualificationSection,
            [ClaimTypeTrigram.StormSnowHail]: DeclarationSection.InternalDamagesSection,
        },
    },
    [Step.HasRoomDamages]: {
        page: DeclarationPage.Questions,
        question: Question.HasRoomDamagesQuestion,
        section: {
            default: DeclarationSection.QualificationSection,
            [ClaimTypeTrigram.StormSnowHail]: DeclarationSection.InternalDamagesSection,
        },
    },
    [Step.OwnerHasDamagesOnTenantProperties]: {
        page: DeclarationPage.Questions,
        question: Question.OwnerHasDamagesOnTenantPropertiesQuestion,
        section: DeclarationSection.ThirdPartySection,
    },
    [Step.LeakageCauseRoom]: {
        page: DeclarationPage.Questions,
        question: Question.LeakageCauseRoomQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.LeakageCauseEquipment]: {
        page: DeclarationPage.Questions,
        question: Question.LeakageCauseEquipmentQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.LeakageCauseEquipmentDetail]: {
        page: DeclarationPage.Questions,
        question: Question.LeakageCauseEquipmentDetailQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.LeakageCauseCommonArea]: {
        page: DeclarationPage.Questions,
        question: Question.LeakageCauseCommonAreaQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.LeakageCauseOutsideHouse]: {
        page: DeclarationPage.Questions,
        question: Question.LeakageCauseOutsideHouseQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.EquipmentAge]: {
        page: DeclarationPage.Questions,
        question: Question.EquipmentAgeQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.OutsiderIntervention]: {
        page: DeclarationPage.Questions,
        question: Question.OutsiderInterventionQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.ElectricalCauseOrigin]: {
        page: DeclarationPage.Questions,
        question: Question.ElectricalCauseOriginQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.ElectricalDeclarationInfos]: {
        page: DeclarationPage.DamagesDeclarationInfos,
    },
    [Step.ElectricalDamageType]: {
        page: DeclarationPage.Questions,
        question: Question.ElectricalTypeOfDamageQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.ElectricalDamageFoodAmount]: {
        page: DeclarationPage.Questions,
        question: Question.ElectricalFoodAmountQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.ElectricalNumberOfDamagedEquipments]: {
        page: DeclarationPage.Questions,
        question: Question.ElectricalNbrOfEquipmentsQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.HasProfessionalIntervened]: {
        page: DeclarationPage.Questions,
        question: Question.HasProfessionalIntervenedQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.ProfessionalInterventionInfo]: {
        page: DeclarationPage.Questions,
        question: Question.ProfessionalInterventionInfoQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.ProfessionalInterventionInvoice]: {
        page: DeclarationPage.Questions,
        question: Question.ProfessionalInterventionInvoiceQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.IsAwareOfPublicWorks]: {
        page: DeclarationPage.Questions,
        question: Question.IsAwareOfPublicWorksQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.PublicWorksInfo]: {
        page: DeclarationPage.Questions,
        question: Question.PublicWorksInfoQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.ElectricalCauseEquipment]: {
        page: DeclarationPage.Questions,
        question: Question.ElectricalCauseEquipmentQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.StormDate]: {
        page: DeclarationPage.Questions,
        question: Question.StormDateQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.ElectricalDamages]: {
        page: DeclarationPage.Questions,
        question: Question.ElectricalDamagesQuestion,
        section: DeclarationSection.ElectricalDamagesSection,
    },
    [Step.ElectricalDamagesDetails]: {
        page: DeclarationPage.Questions,
        question: null,
        section: null,
    },
    [Step.StormCauseType]: {
        page: DeclarationPage.Questions,
        question: Question.StormCauseTypeQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.IsProtectionMeasureDone]: {
        page: DeclarationPage.Questions,
        question: Question.IsProtectionMeasureDoneQuestion,
        section: DeclarationSection.ExternalDamagesSection,
    },
    [Step.IsHelpForProtectionMeasureNeeded]: {
        page: DeclarationPage.Questions,
        question: Question.IsHelpForProtectionMeasureNeededQuestion,
        section: DeclarationSection.ExternalDamagesSection,
    },
    [Step.IsThirdPartyInvolved]: {
        page: DeclarationPage.Questions,
        question: Question.IsThirdPartyInvolvedQuestion,
        section: DeclarationSection.ExternalDamagesSection,
    },
    [Step.RobberyEventType]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyEventTypeQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.RobberyLostKeysCircumstances]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyLostKeysCircumstancesQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.RobberyVandalismAuthorKnown]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyVandalismAuthorKnownQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.RobberyVandalismAuthor]: {
        page: DeclarationPage.Questions,
        question: Question.ThirdPartyQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.RobberyLostKeysLockSelection]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyLostKeysLockSelectionQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.RobberyDuringAbsence]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyDuringAbsenceQuestion,
        // section: DeclarationSection.QualificationSection,
        section: {
            default: DeclarationSection.QualificationSection,
            [InsurerName.Generali]: {
                default: DeclarationSection.QualificationSection,
                [ClaimTypeTrigram.Robbery]: DeclarationSection.RobberyQualificationContinuationVOLSection,
            },
            [InsurerName.LaMedicale]: {
                default: DeclarationSection.QualificationSection,
                [ClaimTypeTrigram.Robbery]: DeclarationSection.RobberyQualificationContinuationVOLSection,
            },
        },
    },
    [Step.PersonalRobberyEventType]: {
        page: DeclarationPage.Questions,
        question: Question.PersonalRobberyEventTypeQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.PersonalRobberyConcernedPerson]: {
        page: DeclarationPage.Questions,
        question: Question.PersonalRobberyConcernedPersonQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.RobberyAbsenceDates]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyAbsenceDatesQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.RobberyLeaveDate]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyLeaveDateQuestion,
        // section: DeclarationSection.QualificationSection,
        section: {
            default: DeclarationSection.QualificationSection,
            [InsurerName.Generali]: {
                default: DeclarationSection.QualificationSection,
                [ClaimTypeTrigram.Robbery]: DeclarationSection.RobberyQualificationContinuationVOLSection,
            },
            [InsurerName.LaMedicale]: {
                default: DeclarationSection.QualificationSection,
                [ClaimTypeTrigram.Robbery]: DeclarationSection.RobberyQualificationContinuationVOLSection,
            },
        },
    },
    [Step.RobberyMethod]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyMethodQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.RobberyHouseSecured]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyHouseSecuredQuestion,
        section: DeclarationSection.AssistanceSection,
    },
    [Step.RobberyHouseSecuredYesNo]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyHouseSecuredYesNoQuestion,
        section: DeclarationSection.AssistanceSection,
    },
    [Step.RobberyHouseIsEmergencyServiceNeeded]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyHouseIsEmergencyServiceNeededQuestion,
        section: DeclarationSection.AssistanceSection,
    },
    [Step.RobberyComplaintNotFiled]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyComplaintNotFiledQuestion,
        section: DeclarationSection.RobberyComplaintSection,
    },
    [Step.RobberyLocation]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyLocationQuestion,
        // section: DeclarationSection.QualificationSection,
        section: {
            default: DeclarationSection.QualificationSection,
            [InsurerName.Generali]: {
                default: DeclarationSection.QualificationSection,
                [ClaimTypeTrigram.Robbery]: DeclarationSection.RobberyQualificationContinuationVOLSection,
            },
            [InsurerName.LaMedicale]: {
                default: DeclarationSection.QualificationSection,
                [ClaimTypeTrigram.Robbery]: DeclarationSection.RobberyQualificationContinuationVOLSection,
            },
        },
    },
    [Step.RobberyProtectionMeans]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyProtectionMeansQuestion,
        // section: DeclarationSection.QualificationSection,
        section: {
            default: DeclarationSection.QualificationSection,
            [InsurerName.Generali]: {
                default: DeclarationSection.QualificationSection,
                [ClaimTypeTrigram.Robbery]: DeclarationSection.RobberyQualificationContinuationVOLSection,
            },
            [InsurerName.LaMedicale]: {
                default: DeclarationSection.QualificationSection,
                [ClaimTypeTrigram.Robbery]: DeclarationSection.RobberyQualificationContinuationVOLSection,
            },
        },
    },
    [Step.RobberyProtectionMeasures]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyProtectionMeasuresQuestion,
        // section: DeclarationSection.QualificationSection,
        section: {
            default: DeclarationSection.QualificationSection,
            [InsurerName.Generali]: {
                default: DeclarationSection.QualificationSection,
                [ClaimTypeTrigram.Robbery]: DeclarationSection.RobberyQualificationContinuationVOLSection,
            },
            [InsurerName.LaMedicale]: {
                default: DeclarationSection.QualificationSection,
                [ClaimTypeTrigram.Robbery]: DeclarationSection.RobberyQualificationContinuationVOLSection,
            },
        },
    },
    [Step.RobberyType]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyTypeQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.RobberyComplaintFiledIntro]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyComplaintFiledIntroQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.RobberyPersonalPropertyCount]: {
        page: DeclarationPage.Questions,
        question: Question.RobberyPersonalPropertyCountQuestion,
        section: DeclarationSection.PersonalPropertyDamagesVOLSection,
    },
    [Step.RobberyDamagesDeclarationInfos]: { page: DeclarationPage.DamagesDeclarationInfos },
    [Step.DamagesDeclarationSynthesis]: {
        page: DeclarationPage.Questions,
        question: Question.DamagesSynthesisQuestion,
        section: DeclarationSection.DamagesSynthesisSection,
    },
    [Step.BreakInDamages]: {
        page: DeclarationPage.Questions,
        question: Question.BreakInDamagesQuestion,
        section: DeclarationSection.BreakInDamagesSection,
    },
    [Step.BreakInDamagesDetails]: {
        page: DeclarationPage.Questions,
        question: null,
        section: null,
    },
    [Step.BreakInDamagesMissingFile]: { page: DeclarationPage.BreakInDamagesMissingFile },
    // [Step.QualificationCertification]: { page: DeclarationPage.QualificationCertification },
    [Step.QualificationCertification]: {
        page: DeclarationPage.Questions,
        question: null,
        section: null,
    },
    [Step.DroughtDeclaration]: { page: DeclarationPage.DroughtDeclaration },
    [Step.DroughtAppointment]: {
        page: DeclarationPage.Questions,
        question: null,
        section: null,
    },
    [Step.RoomDamagesDetails]: {
        page: DeclarationPage.Questions,
        question: null,
        section: null,
    },
    [Step.RoomDamages]: {
        page: DeclarationPage.Questions,
        question: Question.RoomDamagesQuestion,
        section: {
            default: DeclarationSection.RoomDamagesSection,
            [ClaimTypeTrigram.StormSnowHail]: DeclarationSection.InternalDamagesSection,
            [ClaimTypeTrigram.Fire]: DeclarationSection.InternalDamagesSection,
        },
    },
    [Step.PersonalPropertiesDetails]: {
        page: DeclarationPage.Questions,
        question: null,
        section: null,
    },
    [Step.PersonalProperties]: {
        page: DeclarationPage.Questions,
        question: Question.PersonalPropertiesQuestion,
        section: {
            default: DeclarationSection.PersonalPropertyDamagesSection,
            [ClaimTypeTrigram.StormSnowHail]: DeclarationSection.InternalDamagesSection,
            [ClaimTypeTrigram.Robbery]: DeclarationSection.PersonalPropertyDamagesVOLSection,
        },
    },
    [Step.ExternalDamagesDetails]: {
        page: DeclarationPage.Questions,
        question: null,
        section: null,
    },
    [Step.ExternalDamages]: {
        page: DeclarationPage.Questions,
        question: Question.ExternalDamagesQuestion,
        section: DeclarationSection.ExternalDamagesSection,
    },
    [Step.ThirdPartiesDetails]: {
        page: DeclarationPage.Questions,
        question: null,
        section: null,
    },
    [Step.ThirdParties]: {
        page: DeclarationPage.Questions,
        question: Question.ThirdPartyQuestion,
        section: DeclarationSection.ThirdPartySection,
    },
    [Step.Equipments]: {
        page: DeclarationPage.Questions,
        question: Question.EquipmentsQuestion,
        section: {
            default: DeclarationSection.RoomDamagesSection,
            [ClaimTypeTrigram.StormSnowHail]: DeclarationSection.InternalDamagesSection,
        },
    },
    [Step.EquipmentsDetails]: {
        page: DeclarationPage.Questions,
        question: null,
        section: null,
    },
    [Step.Sumup]: {
        page: DeclarationPage.Questions,
        question: null,
        section: null,
    },
    [Step.CompensationOptions]: { page: DeclarationPage.CompensationOptions },
    [Step.CompensationRemainingWorks]: { page: DeclarationPage.CompensationRemainingWorks },
    [Step.CompensationRemainingWorksConfirmation]: {
        page: DeclarationPage.CompensationRemainingWorksConfirmation,
    },
    [Step.CompensationPartnerOption]: { page: DeclarationPage.CompensationPartnerOption },
    [Step.CompensationProposition]: { page: DeclarationPage.CompensationProposition },
    [Step.CompensationLostLeys]: { page: DeclarationPage.CompensationLostLeys },
    [Step.CompensationConfirmation]: { page: DeclarationPage.CompensationConfirmation },
    [Step.CompensationOtherArtisanSendInvoice]: {
        page: DeclarationPage.CompensationOtherArtisanSendInvoice,
    },
    [Step.CompensationLowDeductible]: { page: DeclarationPage.CompensationLowDeductible },
    [Step.CompensationNoDamages]: { page: DeclarationPage.CompensationNoDamages },
    [Step.CompensationNotCoveredGuarantee]: { page: DeclarationPage.CompensationNotCoveredGuarantee },
    [Step.CompensationLostSharedKeys]: { page: DeclarationPage.CompensationLostSharedKeys },
    [Step.CompensationDamagesSumUp]: { page: DeclarationPage.CompensationDamagesSumUp },
    [Step.CompensationSsDiNonOccConvDi]: { page: DeclarationPage.CompensationSsDiNonOccConvDi },
    [Step.ExpertAppointmentByPhoneIndex]: { page: DeclarationPage.ExpertAppointmentByPhoneIndex },
    [Step.ExpertAppointmentByPhoneChoice]: {
        page: DeclarationPage.ExpertAppointmentByPhoneDateSelection,
    },
    [Step.ExpertAppointmentByPhoneConfirmation]: {
        page: DeclarationPage.ExpertAppointmentByPhoneConfirmation,
    },
    [Step.ExpertAppointmentByVisioIndex]: { page: DeclarationPage.ExpertAppointmentByVisioIndex },
    [Step.ExpertAppointmentByVisioVerification]: {
        page: DeclarationPage.ExpertAppointmentByVisioVerification,
    },
    [Step.ExpertAppointmentByVisioChoice]: {
        page: DeclarationPage.ExpertAppointmentByVisioDateSelection,
    },
    [Step.ExpertAppointmentByVisioConfirmation]: {
        page: DeclarationPage.ExpertAppointmentByVisioConfirmation,
    },
    [Step.ExpertAppointmentOnSiteIndex]: { page: DeclarationPage.ExpertAppointmentOnSiteIndex },
    [Step.ExpertAppointmentOnSiteChoice]: {
        page: DeclarationPage.ExpertAppointmentOnSiteDateSelection,
    },
    [Step.ExpertAppointmentOnSiteConfirmation]: {
        page: DeclarationPage.ExpertAppointmentOnSiteConfirmation,
    },
    [Step.ExpertRecontactForAppointment]: {
        page: DeclarationPage.ExpertRecontactForAppointment,
    },
    [Step.ArtisanRepairAppointmentChoice]: {
        page: DeclarationPage.ArtisanRepairAppointmentDateSelection,
    },
    [Step.ArtisanLeakageCauseSearchAppointmentIndex]: {
        page: DeclarationPage.ArtisanLeakageSearchAppointmentIndex,
    },
    [Step.ArtisanLeakageCauseSearchAppointmentChoice]: {
        page: DeclarationPage.ArtisanLeakageSearchAppointmentDateSelection,
    },
    [Step.ArtisanLeakageCauseSearchAppointmentConfirmation]: {
        page: DeclarationPage.ArtisanLeakageSearchAppointmentConfirmation,
    },
    [Step.ArtisanSecuringAppointmentIndex]: {
        page: DeclarationPage.AssistanceIndex,
    },
    [Step.ArtisanSecuringAppointmentChoice]: {
        page: DeclarationPage.ArtisanRobberySecuringAppointmentDateSelection,
    },
    [Step.ArtisanSecuringAppointmentConfirmation]: {
        page: DeclarationPage.ArtisanRobberySecuringAppointmentConfirmation,
    },
    [Step.GlassBreakagePersonalPropertiesNonIncluded]: {
        page: DeclarationPage.Questions,
        question: Question.GlassBreakagePersonalPropertiesNonIncludedQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.GlassBreakageDamageType]: {
        page: DeclarationPage.Questions,
        question: Question.GlassBreakageDamageTypeQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.GlassBreakageSecurity]: {
        page: DeclarationPage.Questions,
        question: Question.GlassBreakageSecurityQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.GlassBreakageSecurityAssistanceNeeded]: {
        page: DeclarationPage.Questions,
        question: Question.GlassBreakageSecurityAssistanceNeededQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.GlassBreakageRoomDamagesCircumstances]: {
        page: DeclarationPage.Questions,
        question: Question.GlassBreakageRoomDamagesCauseQuestion,
        section: DeclarationSection.GlassDamagesSection,
    },
    [Step.GlassBreakageRoomDamagesInstallationDate]: {
        page: DeclarationPage.Questions,
        question: Question.GlassBreakageRoomDamagesInstallationDateQuestion,
        section: DeclarationSection.GlassDamagesSection,
    },
    [Step.GlassBreakageRoomDamagesOrigin]: {
        page: DeclarationPage.Questions,
        question: Question.GlassBreakageRoomDamagesOriginQuestion,
        section: DeclarationSection.GlassDamagesSection,
    },
    [Step.GlassBreakageRoomDamagesThirdPartyContact]: {
        page: DeclarationPage.Questions,
        question: Question.GlassBreakageRoomDamagesThirdPartyContactQuestion,
        section: DeclarationSection.GlassDamagesSection,
    },
    [Step.GlassBreakageRoomDamageDescription]: {
        page: DeclarationPage.Questions,
        question: Question.GlassBreakageRoomDamagesCountQuestion,
        section: DeclarationSection.GlassDamagesSection,
    },
    [Step.GlassDamages]: {
        page: DeclarationPage.Questions,
        question: Question.GlassDamagesQuestion,
        section: DeclarationSection.GlassDamagesSection,
    },
    [Step.GlassDamagesDetails]: {
        page: DeclarationPage.Questions,
        question: null,
        section: null,
    },
    [Step.GlassDamagesReparations]: {
        page: DeclarationPage.Questions,
        question: Question.GlassDamagesReparationsQuestion,
        section: DeclarationSection.GlassDamagesSection,
    },
    [Step.GlassBreakagePersonalPropertyCircumstances]: {
        page: DeclarationPage.Questions,
        question: Question.GlassBreakagePersonalPropertyDamagesOriginQuestion,
        section: DeclarationSection.PersonalPropertyDamagesSection,
    },
    [Step.GlassBreakagePersonalPropertyCircumstancesThirdPartyContact]: {
        page: DeclarationPage.Questions,
        question: Question.GlassBreakagePersonalPropertyDamagesThirdPartyContactQuestion,
        section: DeclarationSection.PersonalPropertyDamagesSection,
    },
    [Step.GlassBreakagePersonalPropertyDescription]: {
        page: DeclarationPage.Questions,
        question: Question.GlassBreakagePersonalPropertyDamagesCountQuestion,
        section: DeclarationSection.PersonalPropertyDamagesSection,
    },
    [Step.FireBeginQuestion]: {
        page: DeclarationPage.Questions,
        question: Question.FireBeginQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireCauseLocationHouseQuestion]: {
        page: DeclarationPage.Questions,
        question: Question.FireCauseLocationHouseQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireCauseLocalOrigin]: {
        page: DeclarationPage.Questions,
        question: Question.FireCauseLocalOrigin,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireCauseExternOrigin]: {
        page: DeclarationPage.Questions,
        question: Question.FireCauseExternOrigin,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireElectricalCause]: {
        page: DeclarationPage.Questions,
        question: Question.FireElectricalCause,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireElectricalBoardAge]: {
        page: DeclarationPage.Questions,
        question: Question.FireElectricalBoardAge,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireElectricalNetworkAge]: {
        page: DeclarationPage.Questions,
        question: Question.FireElectricalNetworkAge,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireThirdParty]: {
        page: DeclarationPage.Questions,
        question: Question.FireThirdPartyList,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireHasProIntervention]: {
        page: DeclarationPage.Questions,
        question: Question.FireHasProIntervention,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireComplaintFiledQuestion]: {
        page: DeclarationPage.Questions,
        question: Question.FireComplaintFiledQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireHasThirdPartyInfo]: {
        page: DeclarationPage.Questions,
        question: Question.FireHasThirdPartyInfo,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireFireplaceCause]: {
        page: DeclarationPage.Questions,
        question: Question.FireFireplaceCause,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireFireplaceAge]: {
        page: DeclarationPage.Questions,
        question: Question.FireFireplaceAge,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireFireplaceWhoMakesWorks]: {
        page: DeclarationPage.Questions,
        question: Question.FireFireplaceWhoMakesWorks,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireWhoMakesWorks]: {
        page: DeclarationPage.Questions,
        question: Question.FireWhoMakesWorks,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireCauseCommonPartsOrigin]: {
        page: DeclarationPage.Questions,
        question: Question.FireCauseCommonPartsOrigin,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireElectricalCauseCommonParts]: {
        page: DeclarationPage.Questions,
        question: Question.FireElectricalCauseCommonParts,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireCauseNeighboorOrigin]: {
        page: DeclarationPage.Questions,
        question: Question.FireCauseNeighboorOrigin,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireOriginVehicle]: {
        page: DeclarationPage.Questions,
        question: Question.FireCauseVehicleThirdPartyContactQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FireHasRealEstateDamages]: {
        page: DeclarationPage.Questions,
        question: Question.FireHasRealEstateDamagesQuestion,
        section: DeclarationSection.QualificationSection,
    },
    [Step.FirePersonalPropertyDescription]: {
        page: DeclarationPage.Questions,
        question: Question.FirePersonalPropertyDescriptionQuestion,
        section: DeclarationSection.PersonalPropertyDamagesSection,
    },
    [Step.FireNumberOfExternalDamages]: {
        page: DeclarationPage.Questions,
        question: Question.FireNumberOfExternalDamages,
        section: DeclarationSection.ExternalDamagesSection,
    },
    [Step.FireWhoIsResponsibleBarbecue]: {
        page: DeclarationPage.Questions,
        question: Question.FireWhoIsResponsibleBarbecue,
        section: DeclarationSection.QualificationSection,
    },
    [Step.ImmediatePayoutImpossible]: { page: DeclarationPage.ImmediatePayoutImpossible },
    [Step.ImmediatePayoutIndex]: { page: DeclarationPage.PayoutIndex },
    [Step.ImmediatePayoutPending]: { page: DeclarationPage.PayoutPending },
    [Step.ImmediatePayoutSuccess]: { page: DeclarationPage.PayoutSuccess },
    [Step.ImmediatePayoutFailure]: { page: DeclarationPage.PayoutFailure },
    [Step.DelayedPayoutIndex]: { page: DeclarationPage.PayoutIndex },
    [Step.DelayedPayoutPending]: { page: DeclarationPage.PayoutPending },
    [Step.DelayedPayoutSuccess]: { page: DeclarationPage.PayoutSuccess },
    [Step.DelayedPayoutFailure]: { page: DeclarationPage.PayoutFailure },
    [Step.Summary]: { page: DeclarationPage.Summary },
    [Step.SummaryValidation]: { page: DeclarationPage.Summary },
    [Step.UserComment]: { page: DeclarationPage.UserComment },
    [Step.InManagement]: {
        page: {
            default: DeclarationPage.InManagement,
            [InsurerName.IMH]: DeclarationPage.WaintingForManagement,
            [InsurerName.MACIF]: DeclarationPage.WaintingForManagement,
            [InsurerName.MAPA]: DeclarationPage.WaintingForManagement,
            [InsurerName.Lite]: DeclarationPage.WaintingForManagement,
        },
    },
    [Step.Maintenance]: { page: DeclarationPage.Maintenance },
    [Step.NeedManagement]: { page: DeclarationPage.NeedManagement },
    [Step.ClaimRefused]: { page: DeclarationPage.ClaimRefused },
    [Step.DocumentsSynthesis]: {
        page: DeclarationPage.Questions,
        question: Question.DocumentsSynthesisQuestion,
        section: DeclarationSection.DocumentsSynthesisSection,
    },
    [Step.DocumentsSynthesisEdition]: {
        page: DeclarationPage.Questions,
        question: null,
        section: null,
    },
    [Step.DeductibleApplicationInfo]: { page: DeclarationPage.NoThirdPartyDeductibleApplicationInfo },
};
export const StepsByQuestionComponent = (() => {
    const result = {};
    for (const step in StepConfigurations) {
        const configuration = StepConfigurations[step];
        if (configuration.page !== DeclarationPage.Questions) {
            continue;
        }
        const question = configuration.question;
        if (!question) {
            continue;
        }
        result[question] = step;
    }
    return result;
})();
export const STEPS = new Set(Object.values(Step));
