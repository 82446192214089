import * as Sentry from '@sentry/browser';
import Vue from 'vue';
import { getClaimInfoFromClaimResponse, getClaimPostDataFromInfo, } from '@declaration/helpers/serializers/claimSerializer';
import { Step } from '@shared/claim/steps';
import { uploadFile } from '@shared/files/uploadFile';
import { serialize } from '@shared/serialization/apiResourceSerializer';
import { ApiResourceName } from '@shared/types/api/api';
import { ClaimTypeTrigram, } from '@shared/types/api/claim';
import { ClaimFileType } from '@shared/types/file';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
import { uuidFromIri } from '@shared/utils/iri';
import { stepDisplayName } from '@shared/helpers/steps';
import { crispPushData } from '@declaration/helpers/crisp';
import { forcableAmount } from '@shared/utils/forcedAmount';
export const name = 'claim';
export const namespaced = true;
const newClaim = () => {
    return {
        id: null,
        caseNumber: null,
        status: null,
        exit: null,
        forcedExit: null,
        nextStep: null,
        stepStack: [],
        damageDate: null,
        expert: null,
        qualificationCertifiedAt: null,
        isAdvisorCallWanted: null,
        isVisioConferenceAccepted: null,
        damagesValidatedAt: null,
        irsi: null,
        chosenCompensationOption: null,
        isCompensationOptionAccepted: null,
        termsAndConditionsAccepted: null,
        invoiceFile: null,
        contractIri: null,
        artisanAppointment: null,
        expertAppointment: null,
        immediatePayout: null,
        delayedPayout: null,
        userContact: { email: null, phoneNumber: null, addressDetails: null },
        thirdPartyIncidentDescription: null,
        isPendingRepairQuotePending: null,
        immediateCompensationAmount: forcableAmount(),
        delayedCompensationAmount: forcableAmount(),
        isMissingInitialFileStepValidated: null,
        noSuitableExpertTimeSlot: false,
        noSuitableExpertTimeSlotReason: null,
        renEmailSent: null,
        renContactInformation: {
            landlinePhone: null,
            mobilePhone: null,
            email: null,
            details: null,
        },
        provision: null,
        warnings: [],
        expertRecontactForAppointment: false,
        userComment: null,
        origin: null,
    };
};
export const state = () => ({
    isClaimFetched: false,
    claim: newClaim(),
    rawClaim: null,
    isUpdatingClaim: false,
});
export const getters = {
    shouldFetchClaim(state) {
        return !!state.claim.id && !state.isClaimFetched;
    },
    currentStep(state) {
        var _a;
        const stepStack = state.claim.stepStack;
        const nextStep = state.claim.nextStep;
        return (_a = nextStep !== null && nextStep !== void 0 ? nextStep : stepStack[stepStack.length - 1]) !== null && _a !== void 0 ? _a : Step.Index;
    },
    currentStatus(state) {
        return state.claim.status;
    },
    userContact(state) {
        return state.claim.userContact;
    },
    validationConstraintsContext(state, _getters, rootState) {
        var _a, _b;
        if (!state.claim || !rootState.settings.appSettings || !rootState.personalProperty.categories) {
            return null;
        }
        let externalDamageOrigin = null;
        if (rootState.qualification.typeTrigram === ClaimTypeTrigram.StormSnowHail) {
            const qualification = rootState.qualification
                .qualification;
            externalDamageOrigin = (_a = qualification.causeType) !== null && _a !== void 0 ? _a : null;
        }
        if (rootState.qualification.typeTrigram === ClaimTypeTrigram.Fire) {
            externalDamageOrigin = ClaimTypeTrigram.Fire;
        }
        return {
            typeTrigram: (_b = rootState.qualification.typeTrigram) !== null && _b !== void 0 ? _b : ClaimTypeTrigram.Empty,
            settings: rootState.settings.appSettings,
            personalPropertyCategories: rootState.personalProperty.categories,
            externalDamageOrigin,
        };
    },
};
export const actions = {
    resetStore({ commit, dispatch }) {
        commit('RESET_STATE');
        dispatch('counting/resetState', null, { root: true });
        dispatch('qualification/resetState', null, { root: true });
        dispatch('contract/resetState', null, { root: true });
        dispatch('artisanAppointment/resetState', null, { root: true });
        dispatch('expertAppointment/resetState', null, { root: true });
        dispatch('personalProperty/resetState', null, { root: true });
        dispatch('externalDamage/resetState', null, { root: true });
        dispatch('breakInDamage/resetState', null, { root: true });
        dispatch('glassDamage/resetState', null, { root: true });
        dispatch('electricalDamage/resetState', null, { root: true });
        dispatch('receipt/resetState', null, { root: true });
        dispatch('roomDamage/resetState', null, { root: true });
        dispatch('thirdParty/resetState', null, { root: true });
    },
    async fetchClaim({ dispatch, state, commit }, payload) {
        var _a;
        const id = (_a = payload === null || payload === void 0 ? void 0 : payload.id) !== null && _a !== void 0 ? _a : state.claim.id;
        const shouldUpdatePage = (payload === null || payload === void 0 ? void 0 : payload.shouldUpdatePage) || false;
        const skipContract = (payload === null || payload === void 0 ? void 0 : payload.skipContract) || false;
        if (!id) {
            return;
        }
        const savedClaim = await this.$axios.$get(`/claims-brms/${uuidFromIri(id)}`);
        await dispatch('updateStoreWithClaimResponse', {
            claimResponse: savedClaim,
            shouldUpdatePage,
        });
        if (!skipContract) {
            await Promise.all([
                dispatch('contract/fetchSelectedContract', null, { root: true }),
                dispatch('contract/fetchApplicableContractDetail', {}, { root: true }),
            ]);
        }
        commit('SET_IS_CLAIM_FETCHED', true);
        return savedClaim;
    },
    async createNewClaim({ commit, dispatch }) {
        const requestBody = {
            step: Step.ContractChoice,
        };
        try {
            const savedClaim = await this.$axios.$post(apiResourcePath(ApiResourceName.Claims), requestBody);
            await dispatch('counting/createNewCounting', savedClaim.id, { root: true });
            const claimInfo = await getClaimInfoFromClaimResponse(savedClaim);
            commit('UPDATE_CLAIM', claimInfo);
            commit('SET_IS_CLAIM_FETCHED', true);
            // update component stack and redirect if necessary
            await dispatch('pages/updateStoreWithClaimResponse', {
                claimResponse: savedClaim,
                shouldUpdatePage: true,
            }, { root: true });
        }
        catch (err) {
            dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
        }
    },
    async getNextStepWithBrms({ state }) {
        if (!state.claim.id) {
            return null;
        }
        const savedClaim = await this.$axios.$get(`/claims-brms/${uuidFromIri(state.claim.id)}`);
        return savedClaim.nextStep;
    },
    async updateStoreWithClaimResponse({ commit, dispatch }, { claimResponse, shouldUpdatePage }) {
        const claimInfo = await getClaimInfoFromClaimResponse(claimResponse);
        commit('UPDATE_CLAIM', claimInfo);
        commit('UPDATE_CLAIM_RAW', claimResponse);
        await dispatch('counting/updateStoreWithClaimResponse', claimResponse, { root: true });
        await dispatch('qualification/updateStoreWithClaimResponse', claimResponse, { root: true });
        await dispatch('roomDamage/updateStoreWithClaimResponse', claimResponse, { root: true });
        await dispatch('receipt/updateStoreWithClaimResponse', claimResponse, { root: true });
        await dispatch('personalProperty/updateStoreWithClaimResponse', claimResponse, {
            root: true,
        });
        await dispatch('externalDamage/updateStoreWithClaimResponse', claimResponse, { root: true });
        await dispatch('equipment/updateStoreWithClaimResponse', claimResponse, { root: true });
        await dispatch('thirdParty/updateStoreWithClaimResponse', claimResponse, { root: true });
        await dispatch('breakInDamage/updateStoreWithClaimResponse', claimResponse, { root: true });
        await dispatch('glassDamage/updateStoreWithClaimResponse', claimResponse, { root: true });
        await dispatch('electricalDamage/updateStoreWithClaimResponse', claimResponse, { root: true });
        // update component stack and redirect if necessary
        dispatch('pages/updateStoreWithClaimResponse', { claimResponse, shouldUpdatePage }, { root: true });
    },
    async saveClaimWithPayload({ state, dispatch, commit }, { throwError, ...payload }) {
        commit('SET_IS_UPDATING_CLAIM', true);
        const serializedPayload = serialize(payload);
        try {
            // 1. Call BRMS with new claim payload including current step
            let savedClaim;
            if (state.claim.id !== null) {
                savedClaim = await this.$axios.$put(`/claims-brms/${uuidFromIri(state.claim.id)}`, serializedPayload);
            }
            else {
                savedClaim = await this.$axios.$post(apiResourcePath(ApiResourceName.Claims), serializedPayload);
            }
            // 2. Update state and page with response
            await dispatch('updateStoreWithClaimResponse', {
                claimResponse: savedClaim,
                shouldUpdatePage: true,
            });
        }
        catch (err) {
            Sentry.captureException(err);
            if (throwError)
                throw err;
            dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
        }
        commit('SET_IS_UPDATING_CLAIM', false);
    },
    async updateAndSaveClaim({ commit, dispatch }, { claimPayload, step }) {
        commit('UPDATE_CLAIM_WITH_PAYLOAD', claimPayload);
        const claimPostPayload = getClaimPostDataFromInfo(claimPayload);
        claimPostPayload.step = step;
        await dispatch('saveClaimWithPayload', claimPostPayload);
    },
    async updateAndSaveClaimStep({ dispatch }, step) {
        const payload = { step };
        await dispatch('saveClaimWithPayload', payload);
    },
    async saveStepWithoutStoreUpdate({ state }, step) {
        if (state.claim.id === null) {
            return;
        }
        await this.$axios.put(`claims-brms/${uuidFromIri(state.claim.id)}`, { step });
    },
    async postAndSaveInvoice({ dispatch, state }, file) {
        if (state.claim.id === null) {
            return;
        }
        try {
            const response = await uploadFile(file, state.claim.id, ClaimFileType.CraftsmanInvoice, this.$axios);
            const updatePayload = {
                step: Step.CompensationOtherArtisanSendInvoice,
                claimPayload: { invoiceFile: response },
            };
            dispatch('updateAndSaveClaim', updatePayload);
        }
        catch (_a) { }
    },
    async deleteInvoice({ dispatch, state }) {
        var _a, _b;
        if (!((_b = (_a = state.claim) === null || _a === void 0 ? void 0 : _a.invoiceFile) === null || _b === void 0 ? void 0 : _b.id)) {
            return;
        }
        await this.$axios.delete(state.claim.invoiceFile.id);
        await dispatch('updateAndSaveClaim', {
            claimPayload: { invoiceFile: null },
            step: Step.CompensationOtherArtisanSendInvoice,
        });
    },
    updateUserContact({ commit }, payload) {
        commit('UPDATE_USER_CONTACT', payload);
    },
    updateThirdPartyIncidentDescription({ commit }, description) {
        commit('UPDATE_THIRD_PARTY_INCIDENT_DESCRIPTION', description);
    },
};
export const mutations = {
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
    SET_IS_UPDATING_CLAIM(state, isUpdating) {
        state.isUpdatingClaim = isUpdating;
    },
    SET_IS_CLAIM_FETCHED(state, isClaimFetched) {
        state.isClaimFetched = isClaimFetched;
    },
    UPDATE_CLAIM_WITH_PAYLOAD(state, claimPayload) {
        Object.entries(claimPayload).forEach(([key, value]) => {
            state.claim[key] = value;
        });
    },
    UPDATE_CLAIM(state, claim) {
        var _a;
        state.claim = claim;
        crispPushData('ref_dossier', claim.caseNumber ? `"${claim.caseNumber}"` : 'Aucun');
        crispPushData('derniere_etape', (_a = stepDisplayName(claim.stepStack[claim.stepStack.length - 1])) !== null && _a !== void 0 ? _a : 'Aucune');
    },
    UPDATE_CLAIM_RAW(state, claim) {
        state.rawClaim = claim;
    },
    UPDATE_THIRD_PARTY_INCIDENT_DESCRIPTION(state, description) {
        state.claim.thirdPartyIncidentDescription = description;
    },
    UPDATE_USER_CONTACT(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            Vue.set(state.claim.userContact, key, value);
        });
    },
};
